<template>
    <el-dialog
            title="套用模板"
            :close-on-click-modal="false"
            append-to-body
            :visible.sync="visible" width="50%">
        <el-form size="small" :model="inputForm" :rules="dataRule" v-loading="loading"  ref="inputForm" @keyup.enter.native="doSubmit()"
                 label-width="120px" @submit.native.prevent>
            <el-form-item label="模板：" prop="id">
                <el-select v-model="inputForm.id" filterable placeholder="请选择模板" style="width: 80%;">
                    <el-option
                            v-for="item in options"
                            :key="item.id"
                            :label="item.templateName"
                            :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="visible = false">取消</el-button>
      <el-button size="small" type="primary" @click="doSubmit()" v-noMoreClick>确定</el-button>
    </span>
    </el-dialog>
</template>

<script>
    export default {
        data () {
            return {
                visible: false,
                loading: false,
                inputForm: {
                    createUser:"",
                    id: '',
                },
                options: [],
                dataRule: {
                    id: [
                        {required: true, message: '请选择模板', trigger: 'change'}
                    ]
                },
            }
        },
        methods: {
            init (exhibitionId) {
                this.inputForm.createUser = exhibitionId;
                this.visible = true
                this.$nextTick(() => {
                    this.$refs['inputForm'].resetFields()
                })
                this.$axios(this.api.infor.catalogTemplateAll, {}, 'get').then(data => {
                    if (data && data.status) {
                        this.options = data.data;
                    }
                })
            },
            // 表单提交
            doSubmit () {
                this.$refs['inputForm'].validate((valid) => {
                    if (valid) {
                        this.loading = true
                        this.$axios(this.api.mana.getTemplate, this.inputForm, 'post').then(data => {
                            this.loading = false
                            if (data && data.status) {
                                this.$message.success(data.msg)
                                this.visible = false
                                this.$emit('refreshDataList')
                            }else{
                                this.$message.error(data.msg)
                            }
                        })
                    }
                })
            }
        }
    }
</script>
